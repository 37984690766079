.disabled {
  opacity: 0.5;

  pointer-events: none;

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

*[disabled] {
  opacity: 0.5;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-primary {
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--color-neutral-white-primary);
}

.only-mobile {
  @media(min-width: 768px) {
    display: none !important;
  }
}
