@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'themes/styles.scss';
@import 'themes/colors.scss';
@import 'themes/dialog.scss';
@import 'themes/keyframes.scss';
@import 'themes/inputs.scss';

* {
  outline: 0;
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  font-weight: 400;
  font-family: 'Be Vietnam Pro', sans-serif;

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: var(--color-main-primary);
  }

  @media(max-width: 768px) {
    //To remove blue highlight when click on mobile
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  background-color: var(--color-neutral-black-primary);
}

.empty-message {
  font-size: 1rem;
  color: var(--color-neutral-gray-quinary);
  font-weight: 300;
}
