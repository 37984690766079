:root {
  --input-font-size: .8rem;
}

input[type="text"], input[type="password"], input[type="email"], input[type="phone"] {
  width: 100%;
  height: 2.625rem;

  background: var(--color-neutral-gray-primary);
  border-radius: 1rem;
  border: 0;

  color: var(--color-neutral-white-primary);
  font-size: var(--input-font-size);

  padding: 0.5rem 1.5rem;

  &::placeholder {
    color: var(--color-neutral-gray-tertiary);
  }
}

input[type="radio"] {
  appearance: none;

  width: 1rem;
  height: 1rem;

  margin-right: 0.5rem;

  background-color: var(--color-neutral-white-primary);
  font: inherit;
  color: currentColor;
  border-radius: 50%;

  &:checked {
    background-color: var(--color-main-primary);
  }
}
