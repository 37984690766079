:root {
  --color-neutral-black-primary: #000000;
  --color-neutral-black-secondary: #101010;
  --color-neutral-black-tertiary: #2F2F2F;
  --color-neutral-black-quaternary: #2B2626;
  --color-neutral-black-quinary: #2B2B2B;
  --color-neutral-black-senary: #1B1B1B;
  --color-neutral-black-septenary: #232323;

  --color-neutral-white-primary: #FFFFFF;
  --color-neutral-white-secondary: #DEDEDE;

  --color-neutral-gray-primary: #484848;
  --color-neutral-gray-secondary: #595959;
  --color-neutral-gray-tertiary: #9D9B9B;
  --color-neutral-gray-quaternary: #AFB1B6;
  --color-neutral-gray-quinary: #B7B7B7;

  --color-main-primary: #A594FD;
  --color-main-secondary: #A95AC5;
  --color-main-tertiary: #221427;
  --color-main-quaternary: #B24AF1;
  --color-main-quinary: #7B71AB;
  --color-main-senary: #52305F;
  --color-main-septenary: #8D53A2;

  --color-feedback-primary: #DA9898;
  --color-feedback-secondary: #782E2A;
  --color-feedback-tertiary: #78592A;
  --color-feedback-quaternary: #7C8AFF;
  --color-feedback-quinary: #2A7857;

  --color-gradient-primary: linear-gradient(125.01deg, #1A1919 11.06%, rgba(26, 25, 25, 0.687437) 38.19%, #141414 67.57%, rgba(50, 50, 50, 0.96) 97.84%);
  --color-gradient-secondary: linear-gradient(90deg, #5F3F7F 9.27%, rgba(255, 255, 255, 0) 95.55%);
  --color-gradient-tertiary: linear-gradient(178.36deg, #533060 52.34%, rgba(48, 28, 55, 0) 98.43%);

  --color-transparent-primary: transparent;
  --color-transparent-secondary: rgba(141, 83, 161, 0.15);
}
