ngneat-dialog {
  .ngneat-dialog-backdrop {
    // backdrop styles

    &.ngneat-dialog-backdrop-visible {
      background: var(--dialog-backdrop-bg, rgba(0, 0, 0, .62));
    }

    .ngneat-dialog-content {
      // dialog content, where your component/template is placed

      border-radius: 20px;
      background-color: var(--color-neutral-black-secondary);
      overflow: visible;

      .ngneat-drag-marker {
        // draggable marker
      }

      .ngneat-close-dialog {
        // 'X' icon for closing the dialog
      }
    }
  }

  @media(max-width: 768px) {
    .ngneat-dialog-backdrop {
      padding: 0;

      .ngneat-dialog-content {
        width: auto !important;
      }
    }
  }
}
